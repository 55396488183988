import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _get from 'lodash/get'

import ResourcePage from '../../components/industry-resources/ResourcePage'
import SEO from '../../components/SEO'
import Video from '../../components/articles/Video'

const VideoTutorialsPage = ({ location }) => {
  const { markdownRemark, allDatoCmsVideoTutorial } = useStaticQuery(graphql`
    query VideoTutorialsPageQuery {
      markdownRemark(
        frontmatter: { path: { eq: "/industry-resources/video-tutorials" } }
      ) {
        frontmatter {
          title
          path
          seoImage {
            ...SeoImageSharpFragment
          }
        }
      }
      allDatoCmsVideoTutorial(sort: { fields: position }) {
        edges {
          node {
            title
            video {
              ...VideoFragment
            }
            runTime
            category
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  const allVideos = allDatoCmsVideoTutorial.edges
  const [videos, setVideos] = useState([])
  const [videoFilter, setVideoFilter] = useState(
    _get(location.state, 'filter', 'All')
  )

  useEffect(() => {
    if (videoFilter === 'All') {
      // Set all data back to state
      setVideos(allVideos)
    } else {
      // Set filter data to state
      setVideos(allVideos.filter(({ node }) => node.category === videoFilter))
    }
  }, [videoFilter]) // Array ensures that effect is only run on state change

  return (
    <ResourcePage uri={pageData.path}>
      <SEO
        title={pageData.title}
        image={pageData.seoImage.childImageSharp.fixed.src}
      />

      <section className="c-section c-section--resourcesList">
        <div className="container">
          <div className="c-resourcesList c-resourcesList--video">
            <div className="c-resourcesFilterWrapper">
              <ul className="c-resourcesFilter">
                <li
                  onClick={() => setVideoFilter('All')}
                  className={videoFilter === 'All' ? 'is-active' : ''}
                >
                  All Tutorials
                </li>
                <li
                  onClick={() => setVideoFilter('Manufacturers')}
                  className={videoFilter === 'Manufacturers' ? 'is-active' : ''}
                >
                  For Manufacturers
                </li>
                <li
                  onClick={() => setVideoFilter('Wholesalers')}
                  className={videoFilter === 'Wholesalers' ? 'is-active' : ''}
                >
                  For Wholesalers
                </li>
                <li
                  onClick={() => setVideoFilter('Caterers & Retailers')}
                  className={
                    videoFilter === 'Caterers & Retailers' ? 'is-active' : ''
                  }
                >
                  For Caterers & Retailers
                </li>
              </ul>
            </div>
            <div className="row">
              {videos.map(({ node }) => (
                <div key={node.title} className="col-12 col-md-6 col-lg-4">
                  <div className="c-resourcesItem mb-3">
                    <div className="c-resourcesItem__video">
                      <Video video={node.video} />
                    </div>
                    <span className="c-resourcesItem__videoInfo">
                      {node.category} <span>|</span> {node.runTime}
                    </span>
                    <h2 className="c-resourcesItem__title">{node.title}</h2>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="c-resourcesList__loadMore">
              <button type="submit" className="c-button c-button--secondary mb-4">
                <ArrowDownSVG className="mr-2" /> Load More
              </button>
            </div> */}
          </div>
        </div>
      </section>
    </ResourcePage>
  )
}

export default VideoTutorialsPage
